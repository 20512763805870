<template>
  <div class="">
    <b-row v-if="!fetchingHotel && hotel">
      <b-col v-for="service of services" :key="service.id" sm="6" lg="4" xl="3">
        <div
          class="items position-relative w-100"
          style="cursor:pointer; user-select: none;  margin: 8px 0;border-radius: 0.457rem; overflow: hidden; height: 150px;"
          @click="openService(service)"
        >
          <b-img
            :src="service.image"
            class="position-relative"
            height="auto"
            rounded
            style="width: 100%; height: 150px;
          min-height: 100px;
          object-fit: cover;"
          />

          <div
            class="font-medium-2 text-center d-flex justify-content-center align-items-center"
            style="position: absolute;z-index: 2;right:0;left:0;bottom: 0;top: 0; background: #00000087"
          >
            <span class="font-circular" style="font-size:28px; color: white">{{
              service.name
            }}</span>
          </div>
        </div>
      </b-col>
    </b-row>

    <div v-else class="d-flex justify-content-center flex-column align-items-center m-1 mt-5">
      <b-spinner style="width: 3rem; height: 3rem;" variant="dark" />
    </div>
  </div>
</template>

<style>
.items.card {
  border-radius: 2rem;
}
.items > .card-body {
  padding: 10px 0;
}
.items > .card-body .card-body {
  padding: 0;
}
</style>

<script>
import { BImg, BCol, BRow, BSpinner } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    BImg,
    BCol,
    BRow,
    BSpinner,
    // BNavItemDropdown,
    // BDropdownItem,
    // BDropdownDivider,
    // BAvatar,
  },
  props: {
    services: {
      type: Array,
      default: () => [
        {
          id: 'hotel-dining',
          to: 'restaurants?type=restaurants',
          type: 'dinein',
          name: 'Hotel Dining',
          badges: ['popular', 'free'],
          menuItems:
            'Mushrooms, Cauliflower Bechamel, Rosemary, Vegan Parmesan, Cashews and Truffle Oil',
          price: 10,
          // eslint-disable-next-line global-require
          image: require('@/assets/images/restaurant-services/hotel-dining.jpeg'),
        },
        {
          id: 'neighbourhood-dining',
          to: 'restaurants',
          type: 'restaurants',
          name: 'Neighborhood Dining',
          badges: ['popular', 'fast'],
          price: 23,
          // eslint-disable-next-line global-require
          image: require('@/assets/images/restaurant-services/neighbourhood-dining.jpeg'),
        },
        {
          id: 'convenience',
          to: 'restaurants?type=convenience',
          type: 'convenience',
          name: 'Convenience',
          price: 43,
          // eslint-disable-next-line global-require
          image: require('@/assets/images/restaurant-services/convenience.jpeg'),
        },
        {
          id: 'housekeeping',
          to: 'housekeeping',
          name: 'Housekeeping',
          price: 43,
          // eslint-disable-next-line global-require
          image: require('@/assets/images/restaurant-services/housekeeping.jpeg'),
        },
        {
          id: 'hotel-services',
          to: 'hotel-services',
          name: 'Hotel Services',
          price: 23,
          // eslint-disable-next-line global-require
          image: require('@/assets/images/restaurant-services/hotel-services.jpeg'),
        },
        {
          id: 'local-activities',
          to: 'local-activities',
          name: 'Local Activities',
          price: 53,
          // eslint-disable-next-line global-require
          image: require('@/assets/images/restaurant-services/local-activities.jpeg'),
        },
        {
          id: 'local-offers',
          to: 'local-offers',
          name: 'Local Offers',
          price: 10,
          // eslint-disable-next-line global-require
          image: require('@/assets/images/restaurant-services/local-offers.jpeg'),
        },
      ],
    },
  },
  data() {
    return {
      fetchingHotel: false,
      badges: {
        popular: {
          title: 'POPULAR',
          variant: 'primary',
        },
        fast: {
          title: 'FAST DELIVERY',
          variant: 'primary',
        },
        free: {
          title: 'FREE DELIVERY',
          variant: 'primary',
        },
      },
    }
  },
  computed: mapState({
    hotel: state => state.hotel.hotel,
    restaurants(state) {
      const restaurants = {}
      if (state.hotel.hotel) {
        restaurants.convenience = state.hotel.hotel.restaurants.filter(
          restaurant => restaurant._id === '60fb9744805e3610e4374ec2'
        )
        restaurants.restaurants = state.hotel.hotel.restaurants.filter(
          restaurant => restaurant._id !== '60fb9744805e3610e4374ec2'
        )
        restaurants.dinein = state.hotel.hotel.dinein || []
      }
      return restaurants
    },
  }),
  async created() {
    await this.fetchHotel()
  },
  methods: {
    ...mapActions({
      updateHotel: 'hotel/updateHotel',
      updateRestaurant: 'restaurant/updateRestaurant',
    }),
    async fetchHotel() {
      try {
        let url
        if (this.$route.query.k) {
          url = `hotels/${encodeURIComponent(
            String(this.$route.query.k)
          )}/${encodeURIComponent(String(this.$route.query.z))}`
        } else if (this.$route.fullPath.includes('demo'))
          url = 'hotels/7AQdldBletbYqJ%2F4tA%3D%3D/epIZoJQAna'
        else return
        this.fetchingHotel = true
        const { data: hotel } = await this.$http.get(url)
        hotel.path = this.$route.fullPath
        await this.updateHotel(hotel)
        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        this.fetchingHotel = false
      }
    },
    openService(service) {
      if (
        service.type &&
        this.restaurants[service.type] &&
        this.restaurants[service.type].length === 1
      ) {
        const restaurant = this.restaurants[service.type][0]
        this.updateRestaurant(restaurant)
        this.$router.push({ name: 'restaurant', query: { id: restaurant._id } })
      } else {
        this.$router.push(service.to)
      }
    },
  },
}
</script>

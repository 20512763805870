<template>
  <div>
    <hotel-services />
    <!--    <filters />-->
    <!--    <restaurants />-->
    <!--    <items />-->
  </div>
</template>

<script>
import {} from 'bootstrap-vue'
import HotelServices from '@/components/HotelServices.vue'
// import Restaurants from '@/components/restaurants.vue'
// import Items from '@/components/items.vue'
// import Filters from '@/components/category.vue'

export default {
  components: {
    // Items,
    // Filters,
    // Restaurants,
    HotelServices,
  },
  data() {
    return {}
  },
}
</script>

<style></style>
